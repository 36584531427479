<template>
	<div id="AccountJudgment">
		<PageTitle title="康宁云医院" height="8rem" fixed
			:backTo="this.$route.query.backPath == 'consultOnline' ? ('/consultOnline/1/' + $route.params.departmentId + '/' + $route.params.doctorId) : ( this.$route.query.backPath == 'check' ? this.$router.go(1) : '/self')">
		</PageTitle>
		<div style="position: fixed;top: 5rem;width: 85%;margin: 0 7.5%;z-index: 9">
			<List :width="100" class="userHead">
				<ListItem>
					<template v-slot:left>
						<img style="width: 50px"
							:src="$store.getters.userInfo.AvatarUrl || require('../assets/personal.png')" />
					</template>
					<template v-slot:right>
						<h4>{{ $store.getters.userInfo.NickName }}，您好！</h4>
						<p style="font-size: 14px">请选择您要操作的账号</p>
					</template>
					<!-- <template v-slot:bottom> -->
						<!-- <p @click="untie">如需解绑手机号，请点击此处</p> -->
					<!-- </template> -->
				</ListItem>
			</List>
		</div>
		<div style="position: relative;top: 2.5rem;width: 85%;margin: 1rem auto;padding-bottom: 1px">
			<List :width="100" class="idCard" v-for="(item,index) in items" :key="index">
				<ListItem :leftWidth="16" :rightWidth="84" @click="chooseMedicalCard(index,item)"
				v-show="(item.mrzh != 0 || item.xzmc !='自费' ||  item.zyhe > 0) && item.zhyxpb != 0 || (item.zhyxpb == 0 && item.xzmc !='自费')"
					style="vertical-align: middle;">
					<template v-slot:left>
						<img :name="index" :src="currentIndex==index?check1:check2" />
					</template>
					<!-- <template v-slot:right>
						<img v-if="item.zhyxpb==0" src="../assets/verification-gray.png">
						<img v-else-if="item.mrzh==1" src="../assets/verification-green.png">
						<img v-else-if="item.mrzh==0" src="../assets/verification-red.png">
						<p> 就诊卡号：{{item.ckhm | filterCardNum}}
						<p> 姓名：{{item.brxm | filterName}} 性别：{{item.brxb}}</p>
						<p> 身份证号：{{item.sfzh | filterIdCardNum}}</p>
						<p> 性质：{{item.xzmc}} &nbsp;余额：{{item.zhye}}元</p>
					</template> -->
					<template v-slot:right>
						<img v-if="item.zhyxpb==0" src="../assets/verification-gray.png">
						<img v-else-if="item.mrzh==1" src="../assets/verification-green.png">
						<img v-else-if="item.mrzh==0" src="../assets/verification-red.png">
						<div style=" line-height: 1.3;letter-spacing: 1px;">
							<p>
								<span style="font-size: 16px;font-weight: 600;margin-right: 10px;">	{{item.brxm}} </span>
								{{item.brxb}}</p>
								<p> 就诊卡号 {{item.ckhm| filterCardNum}}</p>
								<p> 身份证号 {{item.sfzh}}</p>
								<p>
							<van-tag v-if="item.xzmc =='国家医保'" type="primary" size="medium">{{item.xzmc}}</van-tag>
							<van-tag v-else-if="item.xzmc =='住院和救助'" type="warning" size="medium">{{item.xzmc}}</van-tag>
							<van-tag v-else type="success" size="medium" style="padding: 0 15px;">{{item.xzmc}}</van-tag>
							&nbsp;
							<van-tag type="success" plain  size="medium">余额 {{item.zhye}}元</van-tag>
						</p>
					</div>
				</template>
				</ListItem>
			</List>
			<List :width="100" class="idCard" v-if="addShow === 1">
				<ListItem :leftWidth="16" :rightWidth="84" style="vertical-align: middle;" to="/idCardUpload">
					<template v-slot:left>
						<img src="../assets/add.png" />
					</template>
					<template v-slot:right>
						<p style="font-size:14px;margin:15px auto 0 auto;text-decoration: underline">如果您想用此号码注册绑定其他账号
						</p>
						<p style="font-size:14px;text-decoration: underline">请点击这里</p>
						<p>注：需要身份验证，并上传历史病历</p>
					</template>
				</ListItem>
			</List>
		</div>
		<!--信息验证-->
		<van-dialog v-model="showDialog" confirm-button-text="取消" confirm-button-color="#586A94" cancel-button-text="确定"
			title="为保障账户安全，请填写完整信息验证" show-cancel-button class="dialogFont" :before-close="beforeClose">
			<p>姓名</p>
			<div>
        <van-field v-model="dialog.name2" maxlength="1" class="nameInput" />
				<p class="greenTxt">{{dialog.name1}}</p>
				<p class="greenTxt">{{dialog.name3}}</p>
			</div>
			<p>身份证号</p>
			<div style="margin-bottom: 10px">
				<p class="greenTxt" style="margin-top: 8px;vertical-align: top;">{{dialog.idNum1}}</p>
				<van-field v-model="dialog.idNum2" maxlength="4" class="nameInput" style="width: 50px;top: 4px;" />
			</div>
		</van-dialog>
		<van-loading style="margin-top: 100px;" size="24px" vertical v-if="showLoading" class="loading">加载中...
		</van-loading>
	</div>
</template>
<script>
	// @ is an alias to /src
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		QueryAccount,
		CheckPatientInfo,
		AddAccount,
		DeleteByMZKH,
		SetDefaultAccount,
		DeletePhoneById
	} from '@/api/user.js';
	import check1 from '../assets/check1.png';
	import check2 from '../assets/check2.png';

	export default {
		name: 'AccountJudgment',
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
				addShow: 1,
				check1: check1,
				check2: check2,
				currentIndex: 0, //选中第几项
				selectCardId: '', //选中的磁卡号码，验证完设置默认账户时用

				items: [], //查询出来的所有就诊卡

				showDialog: false,
				dialog: {
					name1: '',
					name2: '',
					name3: '',
					idNum1: '',
					idNum2: '',
				},
				postModel: [], //卡号验证的入参
				showLoading: true,
			}
		},
		mounted() {
			this.currentIndex = this.$route.params.selectIndex;
			this.queryAccount();
		},
		methods: {
			pageTo() {
				this.$router.push("/idCardUpload");
			},
			queryAccount() { //查询就诊卡列表
				QueryAccount(1, this.$store.getters.userInfo.PhoneNumber, this.$store.getters.userInfo.UserId).then(
					(result) => {
						this.showLoading = false;
						this.items = result;
            this.addShow = 1;
					},
					() => {
						this.showLoading = false;
            this.addShow = 0;
					}
				).catch(msg => {
          this.$toast(msg);
        });
			},
			untie() { //解绑所有卡
				// if (this.items.length > 0) {
				let untieList = [];
				this.items.forEach(function(item) {
					untieList.push({
						"id": item.id,
						"cardId": item.ckhm,
					});
				})
				this.$dialog.confirm({
					title: '温馨提醒',
					message: '解绑后，该手机账号和关联账户将退出\n如果想要再次使用\n需要重新验证手机号绑定',
					confirmButtonText: '取消',
					cancelButtonText: '继续'
				}).then(() => {
					// on confirm
				}).catch(() => {
					// on cancel
					DeleteByMZKH(untieList).then(
						() => {
							this.$store.dispatch('user/clearLogInfo');
							DeletePhoneById(this.$util.string.getUrlParam(window.location.search).openid)
								.then(() => {}, () => {});

							this.$dialog.alert({
								title: '温馨提醒',
								message: '此手机账号已解绑成功',
								confirmButtonText: '确定',
							}).then(() => {
								// on confirm
								this.$router.push('/self');
							});
						},
						() => {}
					);
				});
				// }
			},
			chooseMedicalCard(index, item) { //选中一张卡
				if (item.zhyxpb == 1) {
					this.currentIndex = index;
					this.selectCardId = item.ckhm;
					if (item.mrzh == 0) { //默认账户(1-默认 2-已验证 0-待验证)
						this.postModel[0] = item;
						this.showDialog = true;
						this.dialog.name1 = item.brxm.substring(1, 2);
						this.dialog.name2 = '';
						if (item.brxm.length > 2) {
							this.dialog.name3 = item.brxm.substring(2);
						} else {
							this.dialog.name3 = '';
						}
						this.dialog.idNum1 = item.sfzh.substring(0, 14);
						this.dialog.idNum2 = '';
					} else
            if (item.mrzh == 1 || item.mrzh == 2) {
						if (this.$route.query.backPath == 'consultOnline') { //选择一张卡跳转回云门诊
							if (item.brxz == 27) {
								this.$dialog.alert({
									title: '温馨提醒',
									message: '参保地为温州地区以外的人员，暂时不能使用云医院复诊开药，敬请期待',
								}).then(() => {});
							} else {
								this.$store.dispatch('pageData/clearInfo'); //清空全局变量
								this.$store.dispatch('pageData/setInfo', item);
								this.$router.push('/consultOnline/1/' + this.$route.params.departmentId + '/' + this.$route
									.params.doctorId);
							}
						} else if (this.$route.query.backPath == 'check') { //选择一张卡跳转回账单明细
							this.$store.dispatch('pageData/clearInfo'); //清空全局变量
							this.$store.dispatch('pageData/setInfo', item);
							this.$router.push("/check/" + this.items[index].ckhm + "/" + this.items[index].brxm + "/" +
								this.items[index].xzmc);
						} else { //选择一张卡跳转至账户明细
							this.$store.dispatch('pageData/clearInfo'); //清空全局变量
							this.$store.dispatch('pageData/setInfo', item);
							this.$router.push('/accountInformation/' + index + '/' + item.patientId);
						}
					}
				}
			},
			beforeClose(action, done) { //弹窗验证操作
				if (action === 'confirm') {
					done();
				} else { //点击确定，验证成功关闭弹窗done()，不成功done(false)；
					this.postModel[0].srbrxm = this.dialog.name2 + this.dialog.name1 + this.dialog.name3;
					this.postModel[0].srsfzh = this.dialog.idNum1 + this.dialog.idNum2.toUpperCase();
					this.postModel[0].srphone = this.postModel[0].sjhm;
					this.postModel[0].id = this.$store.getters.userInfo.UserId;
					CheckPatientInfo(this.postModel).then(
						(result) => {
							if (result[0].namepb == 0) {
								done(false);
								this.dialog.name2 = '';
								this.$toast('姓名验证失败');
							} else if (result[0].sfzpb == 0) {
								done(false);
								this.dialog.idNum2 = '';
								this.$toast('身份证验证失败');
							} else if (result[0].zhyxpb == 0) {
								this.$toast('该磁卡无效');
								done();
							} else {
								done();
								result[0].id = this.$store.getters.userInfo.UserId;
								AddAccount(result[0]).then( //验证成功之后调用这个接口保存这张卡到数据库
									() => {
										let hasDefault = 1;
										for (let item of this.items) {
											if (item.mrzh == 1) {
												hasDefault = 0;
											}
										}
										if (hasDefault == 1) {
											this.$dialog.alert({
												title: '温馨提醒',
												message: '您还未设置默认账户\n是否将当前账户设置为默认账户\n（您也可以在个人主页进行修改）',
											}).then(() => {
												// on right
												SetDefaultAccount(this.selectCardId, this.$store.getters
													.userInfo.UserId).then(
													() => {
														this.$store.SaveSocket = '';
														this.queryAccount();
													},
													() => {}
												);
											});
										} else {
											this.$toast('验证成功');
											this.queryAccount();
										}
									},
									() => {}
								);
							}
						},
						() => {
							done(false);
						}
					);
				}
			}
		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.loading {
		color: @green;
	}

	.idCard {
		.left {
			img {
				width: 25px;
			}
		}
	}

	.userHead {
		.infoContainer {
			padding: 13px 0 20px 0;
		}

		.bottom p {
			text-align: right;
			font-size: 12px;
			float: right;
			padding-right: 5px;
		}
	}

	.infoContainer {
		padding: 13px 0;

		p {
			margin: 0;
		}

		.top p {
			text-align: center;
		}

		.right {
			h4 {
				margin: 0 0 10px;
			}

			p {
				font-size: @font-size-sm;
				//文本超出一行用省略号表示
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				word-break: break-all;
			}

			img {
				position: absolute;
				top: -13px;
				right: 0;
				width: 60px;
			}
		}
	}

	.bottomAdd {
		position: fixed;
		bottom: 0;
		width: 100%;
		text-align: center;
		background: white;

		p {
			color: @green;
			margin: 0;
			font-size: @font-size-sm;
		}
	}

	.greenTxt {
		display: inline-block;
		color: @green;
	}

	.dialogFont {
		text-align: center;
		font-size: @font-size-md;

		p {
			margin: 5px 0;
		}

		.nameInput {
			width: 30px;
			height: 24px;
			border-bottom: 1px solid #3F9775;
			padding: 0 0 0 7px;
			display: inline-block;
			top: 6px;
			line-height: 25px;
		}
	}

	
</style>
